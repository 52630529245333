import React from "react";
import Layout from "../components/layout";
import * as styles from "./iems.module.css";
import * as styleMain from "../styles/main.module.css";
import bannerPhoto3 from "../images/bannerPhoto3.png";
import blueAvetmissIcon from "../images/blueAvetmissIcon.png";
import blueUsiIcon from "../images/blueUsiIcon.png";
import blueCricosIcon from "../images/blueCricosIcon.png";
import blueStaffIcon from "../images/blueStaffIcon.png";
// import  from "../images/.png";

const Iems = () => {
  return (
    <Layout>
      <main className={styles.iems}>
        {/* ====== 0 banner ====== */}
        <section className={styleMain.banner}>
          <h2>Help towards business success</h2>
          <h5>
            We focus on your business, not the computers, providing
            cost-effective systems
            <br />
            that save time, effort and money by meeting real business needs.
          </h5>
          <button className={styleMain.bannerBtn}>FIND OUT MORE</button>
          <img
            className={styleMain.bannerImg}
            src={bannerPhoto3}
            alt="banner_photo_3"
          />
        </section>
        {/* ====== 1 iemsBrief ====== */}
        <section className={styles.iemsBrief}>
          <h3>IEMS</h3>
          <p>
            Auokka is developing an ecosystem to get involved with trainee,
            trainers, institutes, universities and enterprises to provide
            quality continue education and after-degree career services.
          </p>
          {/* <p>
            Auokka is developing an ecosystem to get involved with trainee,
            trainers, institutes, universities and enterprises to provide
            quality continue education and after-degree career services. Auokka
            is developing an ecosystem to get involved with trainee, trainers,
            institutes, universities and enterprises to provide quality continue
            education and after-degree career services. Auokka is developing an
            ecosystem to get involved with trainee, trainers, institutes,
            universities and enterprises to provide quality continue education
            and after-degree career services.Auokka is developing an ecosystem
            to get involved with trainee, trainers, institutes, universities and
            enterprises to provide quality continue education and after-degree
            career services.
          </p> */}
        </section>
        {/* ====== 2 platformFeature ====== */}
        <section className={styles.platformFeature}>
          <aside>
            <h3>Platform features</h3>
            {/* <p>
              Course Planning is the process of constructing learning components
              in a way that can be delivered most effectively to your learners.
              IVEMS provides course design options enabling you to construct
              simple (stand alone units) to complex courses (units broken into
              stages of learning eg terms, semesters). Once the course has been
              designed, the curriculum structure can be reviewed and approved
              using our curriculum.
            </p> */}
          </aside>
          <div className={styles.flex}>
            <article>
              <img src={blueAvetmissIcon} alt="blue_avetmiss_icon" />
              <h4>Insights</h4>
              <p>
                We treat students as our core business covering all aspects of
                training. We treat students as our core business covering all
                aspects of training. We treat students as our core business
                covering all aspects of training. We treat students as our core
                business covering all aspects of training. We treat students as
                our core business covering all aspects of training.
              </p>
            </article>
            <article>
              <img src={blueUsiIcon} alt="blue_usi_icon" />
              <h4>Efficiency</h4>
              <p>
                We offer one-stop platform to maximise user experience and
                provide instant results to teachers daily queries across various
                internal and external systems.We offer one-stop platform to
                maximise user experience and provide instant results to teachers
                daily queries across various internal and external systemsWe
                offer one-stop platform to maximise user experience and provide
                instant results to teachers daily queries across various
                internal and external systems.
              </p>
            </article>
            <article>
              <img src={blueCricosIcon} alt="blue_cricos_icon" />
              <h4>Change</h4>
              <p>
                We help schools to explore school data to change and improve
                existing operations to provide better learning experience. We
                help schools to explore school data to change and improve
                existing operations to provide better learning experience. We
                help schools to explore school data to change and improve
                existing operations to provide better learning experience.
              </p>
            </article>
            <article>
              <img src={blueStaffIcon} alt="blue_staff_icon" />
              <h4>Growth</h4>
              <p>
                We provide data analytics for schools to closely monitor
                student's growth.We provide data analytics for schools to
                closely monitor student's growthWe provide data analytics for
                schools to closely monitor student's growth.
              </p>
            </article>
          </div>
        </section>
        {/* ====== 3 researchBg ====== */}
        <section className={styles.researchBg}>
          <h3>Research background</h3>
          <p>
            Every student has different progress. Many student who fall behind
            never catch up, while many highly able students are not stretched.
            Australian research shows that achievement can be spread over up to
            eight year-levels within a single class: a Year 7 class may have
            students working at a Year 1 level, while some others have mastered
            concepts from Year 8. However, making sure every student leams is
            the core business of schools. We judge the success of our education
            by how much progress our students make that will reinforces the
            value of effort and persistence, and further, support future success
            in life.
          </p>
          <p>
            Schools and teachers have long understood this challenge, yet many
            still struggle to respond effectively. In fact, streaming students
            or holding back low performers is not the answer.Instead, teaching
            should focus on each student's needs. This requires we have accurate
            information about what students know and are ready to learn
            next.Actually, many schools have collected sufficient data in their
            daily teaching. Formative assessment, teacherstudent feedback and
            evaluation of teaching programs have been proven to have a
            significant effort on learning. However, such data haven't been
            fully explored or exposed their potential
          </p>
        </section>
      </main>
    </Layout>
  );
};

export default Iems;

export const Head = () => <title>Auokka | IEMS</title>;
